<template>
    <div class="container">
        <div class="page-body">
            <div class="version-info">
                <div class="version-title">
                    纯净版
                    <div class="line"></div>
                </div>
                <div class="version-desc">
                    您的小小支持能为小囊带来无限动力~
                </div>
                <div class="price-wrapper">
                    <div v-for="(item, index) in versionMealList" :key="index" @click="handleSelect(index)"
                        :class="selected == index ? 'price-card active' : 'price-card'">
                        <div :class="selected == index ? 'period active' : 'period'">{{ item.period }}个月</div>
                        <div :class="selected == index ? 'price active' : 'price'">￥{{ item.currentPrice }}</div>
                        <div v-if="index == 1" style="text-decoration: none;"
                            :class="selected == index ? 'desc active' : 'desc'">0.16元/天</div>
                        <div v-else :class="selected == index ? 'desc active' : 'desc'">￥{{ item.originalPrice }}
                        </div>
                    </div>
                </div>
                <div class="un-suport">
                    <image class="icon" src="../../images/warn.png" mode="" />
                    苹果手机用户通过此页面开通会员
                </div>
                <img class="about-version" src="@/assets/images/about-version.png" alt="" />
                <div class="oper-area">
                    <div class="bottom-line"></div>
                    <div class="button-version" @click="openVersion">
                        <div>
                            ￥{{ payAmount }}
                        </div>
                        <div>
                            立即开通
                        </div>
                    </div>
                    <!-- <div class="server-desc">订阅即视为同意我们的购买须知和应用政策</div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getVersionMealList } from '@/api/index';
export default {
    data() {
        return {
            selected: 1,
            pageNum: 1,
            pageSize: 100,
            versionMealList: [],
            payAmount: 0,
        }
    },
    methods: {
        handleSelect(index) {
            console.log(index);
            this.selected = index,
                this.payAmount = this.versionMealList[index].currentPrice
        },
        getVersionMeals(params) {
            params.pageSize = this.pageSize;
            params.pageNum = this.pageNum;
            params.versionId = 1;
            getVersionMealList(params).then(response => {
                this.versionMealList = response.rows;
                this.payAmount = response.rows[this.selected].currentPrice;
            });
        },
        async openVersion() {
            const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            var data = {
                payAmount: this.payAmount,
                productType: 1, // 会员
                mealId: this.versionMealList[this.selected].mealId
            };
            console.log(data);
            // let createOrderResponse = await createOrder(data);
            // if (createOrderResponse.data.code != 200) {
            //     wx.showToast({
            //         title: '创建订单失败',
            //         icon: 'error'
            //     });
            //     return;
            // }
            // data = createOrderResponse.data.data;
            // data.platform = 2;
            // let activePayResponse = await activePay(data);
            // if (activePayResponse.data.code != 200) {
            //     wx.showToast({
            //         title: '订单激活失败',
            //         icon: 'error'
            //     });
            //     return;
            // }
            // data = {
            //     prepayId: activePayResponse.data.data
            // }
            // let appConfigResponse = await appPayConfig(data);
            // if (appConfigResponse.data.code != 200) {
            //     wx.showToast({
            //         title: '支付配置异常',
            //         icon: 'error'
            //     });
            //     return;
            // }
            // wx.hideLoading();
            // wx.requestPayment({
            //     "timeStamp": appConfigResponse.data.data.timeStamp,
            //     "nonceStr": appConfigResponse.data.data.nonceStr,
            //     "package": appConfigResponse.data.data.packageValue,
            //     "signType": appConfigResponse.data.data.signType,
            //     "paySign": appConfigResponse.data.data.paySign,
            //     "success": function (res) {
            //         wx.navigateTo({
            //             url: '../pay-result/index?status=success',
            //         });
            //     },
            //     "fail": function (res) {
            //         console.log("失败");
            //         wx.navigateTo({
            //             url: '../pay-result/index?status=fail',
            //         });
            //     },
            //     "complete": function (res) {
            //         console.log("完成");
            //     }
            // })
        },
    },
    mounted() {
        this.getVersionMeals({});
    }
}
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.page-body {
    display: flex;
    flex-direction: column;
    padding: 0px 16.5px;
    height: 96vh;
    overflow: hidden;
    position: relative;
}

.version-info {
    width: 100%;
    margin-top: 10px;
    height: 100%;
    position: relative;
}

.version-title {
    position: relative;
    display: inline-block;
    color: #000;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    padding: 0 10px;
    margin-left: 10px;
}

.version-title .line {
    position: absolute;
    background-color: #46B4B1;
    width: 90px;
    height: 10px;
    border-radius: 4px;
    bottom: 10px;
    left: 0px;
    z-index: -10;
}

.version-desc {
    color: #000;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 200% */
    margin-left: 10px;
}

.price-wrapper {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
}

.price-wrapper .price-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 72px;
    border-radius: 10px;
    border: 2px solid #ECECEC;
    background: #F6F6F6;
    padding: 9px 14px 0px 14px;
}

.price-wrapper .active {
    border-color: #46B4B1 !important;
    background-color: #EAF8F7 !important;
    color: #46B4B1 !important;
}

.price-wrapper .price-card .period {
    color: #787878;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 200% */
}

.price-wrapper .price-card .price {
    margin-top: 4px;
    color: #333;
    font-family: "PingFang SC";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
}

.price-wrapper .price-card .desc {
    margin-top: 13px;
    color: #787878;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-decoration: line-through;
    /* 233.333% */
}

.un-suport {
    color: #7E7E7E;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 51px;
    /* 426.861% */
}

.un-suport .icon {
    width: 20px;
    height: 20px;
}

.about-version {
    width: 100%;
}

.bottom-line {
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
}

.oper-area {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-version {
    background: #46B4B1;
    border-radius: 20px;
    color: #FFF;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 175% */
    padding: 6px 40px;
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
}

.server-desc {
    color: #BFBFBF;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 280% */
    text-decoration-line: underline;
}
</style>