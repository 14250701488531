import request from "@/utils/request"

export function getAccessToken(query) {
    return request({
        url: '/api-wx/cgi-bin/token',
        method: 'get',
        params: query
    });
}

export function createQrCode(query,data) {
    return request({
        url: '/api-wx/wxa/getwxacodeunlimit',
        method: 'post',
        params: query,
        data: data,
        responseType: 'blob'
    });
}

export function login(data) {
    return request({
        url: '/api-nz/nz/login',
        headers: {
            isToken: false,
            repeatSubmit: false
          },
        method: 'post',
        data: data,
    });
}

export function getStatData(data) {
    return request({
        url: '/api-nz/nz/common/getStatData',
        headers: {
            isToken: false,
            repeatSubmit: false
          },
        method: 'get',
        data: data,
    });
}

export function getUserInfo(query) {
    return request({
        url: '/api-nz/nz/getUserInfo',
        method: 'get',
        params: query,
    });
}

// 退出方法
export function logout() {
    return request({
      url: '/api-nz/logout',
      method: 'post'
    })
  }

export function bindInfo(data) {
    return request({
        url: '/api-nz/nz/bindInfo',
        headers: {
            isToken: false,
        },
        method: 'put',
        data: data,
    });
}

export function updateScanCodeStatus(data) {
    return request({
        url: '/api-nz/nz/user/updateScanCodeStatus',
        headers: {
            isToken: false,
          },
        method: 'post',
        data: data,
    });
}



export function getScanCodeStatus(query) {
    return request({
        url: '/api-nz/nz/user/getScanCodeStatus',
        headers: {
            isToken: false,
          },
        method: 'get',
        params: query,
    });
}

export function getVersionMealList(query) {
    return request({
        url: '/api-nz/nz/versionMeal/list',
        headers: {
            isToken: false,
          },
        method: 'get',
        params: query,
    });
}

export function createOrder(data) {
    return request({
        url: '/api-nz/nz/userPayOrder/create',
        method: 'post',
        data: data,
    });
}

export function activePay(data) {
    return request({
        url: '/api-nz/pay/activePay',
        method: 'post',
        data: data,
    });
}

export function appPayConfig(query) {
    return request({
        url: '/api-nz/pay/appPayConfig',
        headers: {
            isToken: false,
          },
        method: 'get',
        params: query,
    });
}

export function getAreaList(query) {
    return request({
        url: '/api-nz/nz/area/list',
        headers: {
            isToken: false,
          },
        method: 'get',
        params: query,
    });
}

export function getTagList(query) {
    return request({
        url: '/api-nz/nz/tag/list',
        headers: {
            isToken: false,
          },
        method: 'get',
        params: query,
    });
}

export function insertArticleDraft(data) {
    return request({
        url: '/api-nz/nz/articleDraft',
        method: 'post',
        data: data,
    });
}

export function updateArticleDraft(data) {
    return request({
        url: '/api-nz/nz/articleDraft',
        headers: {
            isToken: false,
          },
        method: 'put',
        data: data,
    });
}